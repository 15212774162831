import { useContext, useState } from "react";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";
import { Content } from "../Settings.style";
import { ManualDiscountsModal } from "./ManualDiscountsModal";
import {
  GetManualPromotionsDocument,
  useDeleteManualPromotionMutation,
  useGetManualPromotionsQuery,
} from "../../../../generated/graphql";
import { LoadingBox } from "../../../shared/Style/Style";
import { UserInfoContext } from "../../Main";

const getManualDiscountsView = (manualDiscounts) =>
  manualDiscounts.map((promotion) => ({
    ...promotion,
    name: (
      <div
        style={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          maxWidth: 270,
        }}
      >
        <div style={{ fontWeight: 600 }}>{promotion.name}:</div>
        <div>{promotion.tariff.name}</div>
        <div>Знижка: {promotion.discount}%</div>
        <div>
          {new Date(promotion.startDate).toLocaleDateString("uk-UA", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}{" "}
          -{" "}
          {new Date(promotion.endDate).toLocaleDateString("uk-UA", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      </div>
    ),
  }));

export const ManualDiscounts = () => {
  const isUserCanCreatePromotion = useContext(
    UserInfoContext
  )?.userPermissions?.includes("CAN_CREATE_PROMOTION");
  const [currentDiscount, setCurrentDiscount] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [needClearName, setNeedClearName] = useState(false);
  const [deletePromotion] = useDeleteManualPromotionMutation();

  const {
    data: {
      getManualPromotions: { promotions: manualDiscountsData = [] } = {},
    } = {},
    loading,
  } = useGetManualPromotionsQuery();

  const manualDiscounts = manualDiscountsData?.map((promotion) => ({
    id: promotion.id,
    name: promotion.name,
    discount: promotion.discount,
    code: promotion.code,
    startDate: promotion.startDate,
    endDate: promotion.expiredDate,
    tariff: {
      id: promotion.allowedPlans[0]?.id,
      name: `${promotion.allowedPlans[0]?.description}, ${promotion.allowedPlans[0]?.monthsCount} міс.`,
    },
  }));

  const handleEdit = (isEditing: { itemId: string; status: boolean }) => {
    const itemId = isEditing.itemId;

    if (!itemId) return;

    const currentDiscount = manualDiscounts.find(
      (discount) => discount.id === itemId
    );

    setCurrentDiscount(currentDiscount);
    setNeedClearName(false);
    setOpenModal(true);
  };

  const handleCreate = (name: string) => {
    if (!name) {
      setInputError(true);
      return;
    }

    setCurrentDiscount({
      name,
    });
    setNeedClearName(false);
    setOpenModal(true);
  };

  const handleDelete = (deleteManualPromotionId: string) => {
    deletePromotion({
      variables: { deleteManualPromotionId },
      refetchQueries: [{ query: GetManualPromotionsDocument }],
    });
  };

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  return (
    <Content>
      {openModal && (
        <ManualDiscountsModal
          clearName={() => setNeedClearName(true)}
          currentDiscount={currentDiscount}
          onClose={() => setOpenModal(false)}
        />
      )}
      <SettingsCreateItemForm
        header="Акції"
        createItem={handleCreate}
        setCreating={() => {}}
        inputError={inputError}
        setInputError={setInputError}
        areButtonsDisabled={!isUserCanCreatePromotion}
        isRequestSuccessful={needClearName}
        isPromotionDiscount
      />
      <SettingsList
        list={getManualDiscountsView(manualDiscounts)}
        updateItem={() => {}}
        deleteItem={handleDelete}
        buttonsDisabled={!isUserCanCreatePromotion}
        isEditing={{
          itemId: "",
          status: false,
        }}
        setEditing={handleEdit}
      />
      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">Вы ввели некорректное название</Alert>
      </Snackbar>
      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">errorMessage</Alert>
      </Snackbar>
    </Content>
  );
};
