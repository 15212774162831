import React, { useState, useEffect } from "react";

import { Dialog } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { Close } from "@material-ui/icons";
import {
  CloseButton,
  DateRangeContent,
  DateRangeInput,
  DateRangeTitle,
  DateRangeButtonsWrapper,
} from "./DateRangePicker.style";
import {
  ButtonCustom,
  CancelButton,
  defaultMaterialTheme,
} from "../Style/Style";
import { setUTCDateFormate } from "../Utils/UTCDateFormate/setUTCDateFormate";

interface IDateRangePickerProps {
  setDateRange(newDateRange: IDateRange): void;
  open: boolean;
  onClose(): void;
}

export interface IDateRange {
  startDate: Date | string;
  endDate: Date | string;
}

export const initialDateRange = {
  startDate: undefined,
  endDate: undefined,
};

export const DateRangePicker = ({
  open,
  onClose,
  setDateRange,
}: IDateRangePickerProps) => {
  const [dates, setDates] = useState<IDateRange>(initialDateRange);
  const [lastSaveDates, setLastSaveDates] =
    useState<IDateRange>(initialDateRange);

  useEffect(() => {
    if (open) {
      setLastSaveDates({
        startDate: setUTCDateFormate(dates.startDate),
        endDate: setUTCDateFormate(dates.endDate),
      });
    }
  }, [open]);

  const saveHandler = (): void => {
    setDateRange({
      startDate: setUTCDateFormate(dates.startDate),
      endDate: setUTCDateFormate(dates.endDate),
    });
    onClose();
  };

  const resetHandler = (): void => {
    setDates({
      startDate: lastSaveDates.startDate,
      endDate: lastSaveDates.endDate,
    });

    setDateRange(initialDateRange);
  };

  const closeHandler = (): void => {
    setDateRange(initialDateRange);
    onClose();
  };

  return (
    <Dialog open={open}>
      <CloseButton onClick={closeHandler}>
        <Close fontSize="small" />
      </CloseButton>

      <DateRangeTitle>Выберите диапазон дат</DateRangeTitle>

      <DateRangeContent>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DateRangeInput
            variant="inline"
            autoOk
            format="dd.MM.yyyy"
            label="Начало периода"
            value={dates.startDate}
            maxDateMessage="Дата начала позже даты конца"
            maxDate={dates.endDate}
            onChange={(value) => setDates({ ...dates, startDate: value })}
          />

          <DateRangeInput
            autoOk
            variant="inline"
            format="dd.MM.yyyy"
            label="Конец периода"
            value={dates.endDate}
            minDateMessage="Дата конца раньше даты начала"
            minDate={dates.startDate}
            onChange={(value) => setDates({ ...dates, endDate: value })}
          />
        </ThemeProvider>
      </DateRangeContent>

      <DateRangeButtonsWrapper>
        <CancelButton width={"200"} onClick={resetHandler}>
          Сбросить
        </CancelButton>

        <ButtonCustom width={"200"} onClick={saveHandler}>
          Сохранить
        </ButtonCustom>
      </DateRangeButtonsWrapper>
    </Dialog>
  );
};
