import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  BlockUserDocument,
  BlockUserMutation,
  GetUserAppDocument,
  GetUserAppQuery,
  User,
  useUpdateUserInAdminPanelMutation,
} from "../../generated/graphql";
import { useRequestError } from "../../hooks";
import { AvatarPicker } from "../shared/AvatarPicker";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { UserCountrySelect } from "./Selectes/UserCountrySelect";
import { UserRegionSelect } from "./Selectes/UserRegionSelect";
import { UserCitiesSelect } from "./Selectes/UserCitiesSelect";
import { UserSubjectsSelect } from "./Selectes/UserSubjectsSelect";
import { CustomSwitch } from "../ParticipantsModal/ParticipantsModal.style";
import { UserRoleSelect } from "./Selectes/UserRoleSelect";
import { UserSchoolClasses } from "./Selectes/UserSchoolClasses";
import { UserElectivesByPupil } from "./Selectes/UserElectivesByPupil";
import { convertDateStringToLocaleHandler } from "../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import {
  ButtonCustom,
  defaultMaterialTheme,
  Header,
  LoadingBox,
} from "../shared/Style/Style";
import {
  Subtitle,
  UserFields,
  VerticalLine,
  SchoolList,
  SchoolListItem,
  SchoolLink,
} from "./Style";
import { SchoolPaths } from "../shared/constants";

export const UserEditApp = () => {
  const { userId } = useParams<{ userId: string }>();
  const [mobileUsers, setMobileUsers] = useState<User>(null);

  const getAllItemsParams = {
    limit: 500,
    skip: 0,
  };

  const { data, error, loading } = useQuery<GetUserAppQuery>(
    GetUserAppDocument,
    { variables: { userId } }
  );

  const [
    updateUserApp,
    { loading: updateUserAppLoading, error: updateUserAppError },
  ] = useUpdateUserInAdminPanelMutation({
    variables: {
      updateUserInAdminPanelId: userId,
      userData: {
        firstName: mobileUsers?.firstName,
        lastName: mobileUsers?.lastName,
        birthday: mobileUsers?.birthday,
        phone: mobileUsers?.phone,
        roleId: mobileUsers?.role?.id,
        countryId: mobileUsers?.countryId,
        cityId: mobileUsers?.schoolCityId,
        schoolRegionId: mobileUsers?.schoolRegionId,
        schoolClassroomId: mobileUsers?.schoolClassroomId,
      },
    },
    refetchQueries: [
      {
        query: GetUserAppDocument,
        variables: { userId },
      },
    ],
  });

  const [blockUser, { loading: blockUserLoading, error: blockUserError }] =
    useMutation<BlockUserMutation>(BlockUserDocument, {
      refetchQueries: [
        {
          query: GetUserAppDocument,
          variables: { userId },
        },
      ],
    });

  const gqlError = error || updateUserAppError || blockUserError;

  const { requestError, setRequestError } = useRequestError(gqlError);

  // const [
  //   verifiedSchoolTeacher,
  //   {
  //     loading: verifiedSchoolTeacherLoading,
  //     error: verifiedSchoolTeacherError,
  //   },
  // ] = useMutation<VerifiedSchoolTeacherMutation>(
  //   VerifiedSchoolTeacherDocument,
  //   {
  //     variables: {
  //       userId,
  //     },
  //   }
  // );

  // const [
  //   verifiedSchoolAdmin,
  //   { loading: verifiedSchoolAdminLoading, error: verifiedSchoolAdminError },
  // ] = useMutation<VerifiedSchoolAdminMutation>(VerifiedSchoolAdminDocument, {
  //   variables: {
  //     userId,
  //     isVerified: true,
  //   },
  // });

  const updateUserHandle = async () => {
    try {
      await updateUserApp();
    } catch (e) {
      console.error(e);
    }
  };

  // const verifiedTeacherHandler = async () => {
  //   try {
  //     await verifiedSchoolTeacher();
  //   } catch (e) {
  //     setRequestError(true);
  //     setErrorMessage("При подтверждении произошла ошибка");
  //   }
  // };
  // const verifiedAdminHandler = async () => {
  //   try {
  //     await verifiedSchoolAdmin();
  //   } catch (e) {
  //     setRequestError(true);
  //     setErrorMessage("При подтверждении произошла ошибка");
  //   }
  // };

  useEffect(() => {
    if (data) {
      setMobileUsers(data.getUser);
    }
  }, [data]);

  let content = null;

  if (loading || updateUserAppLoading || blockUserLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const errorMessage =
    (error && "При загрузке списка произошла ошибка") ||
    (updateUserAppError &&
      "При обновлении данных пользователя произошла ошибка") ||
    (blockUserError && "При блокировке пользователя произошла ошибка");

  if (mobileUsers) {
    const handlerSetCountry = (countryId: string) => {
      setMobileUsers((prev) => ({
        ...prev,
        countryId,
        schoolId: null,
        schoolRegionId: null,
        schoolCityId: null,
      }));
    };

    const handlerSetRegionId = (schoolRegionId: string) => {
      setMobileUsers((prev) => ({
        ...prev,
        schoolRegionId,
        schoolId: null,
        schoolCityId: null,
      }));
    };

    const handlerSetCity = (schoolCityId: string) => {
      setMobileUsers((prev) => ({ ...prev, schoolCityId, schoolId: null }));
    };

    const handleSetRole = (roleId: number) => {
      setMobileUsers((prev) => ({ ...prev, role: { id: roleId } }));
    };

    const handleSetSchoolRoom = (schoolClassroomId: string) => {
      setMobileUsers((prev) => ({ ...prev, schoolClassroomId }));
    };

    const blockUserHandle = async (id: string) => {
      if (!id) return;

      try {
        await blockUser({
          variables: {
            userId: id,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const {
      firstName,
      lastName,
      birthday,
      phone,
      photo,
      email,
      role,
      createdDate,
      countryId,
      schoolRegionId,
      schoolCityId,
      schoolId,
      applicationId,
      subjects,
      status,
      schools,
      schoolClassroomId,
    } = mobileUsers;

    const personalSchool = schools?.filter(({ isPersonal }) => isPersonal);
    const nonPersonalSchools = schools?.filter(({ isPersonal }) => !isPersonal);

    /* const updateCityAndRegionHandler = async () => {
      try {
        await updateUserApp({
          variables: {
            userData: {
              id: userId,
              schoolCityId,
              schoolRegionId,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }; */

    content = (
      <Box
        display="flex"
        boxShadow={3}
        p="20px 36px"
        borderRadius="5px"
        mt="40px"
        mb="26px"
        flexWrap="wrap"
      >
        <Box flex="0 0 50%" display="flex" flexDirection="column">
          <Subtitle>Персональные данные</Subtitle>
          <Box display="flex">
            <Box flex="0 0 30%">
              <AvatarPicker
                photo={photo}
                uploadHandler={(newPhoto) => {
                  updateUserApp({
                    variables: {
                      updateUserInAdminPanelId: userId,
                      userData: {},
                      photo: newPhoto,
                    },
                  });
                }}
              />
            </Box>
            <Box flex="0 0 70%" pr="20px" flexDirection="column">
              <UserFields
                fullWidth
                label="Имя"
                value={firstName}
                onChange={({ target: { value } }) =>
                  setMobileUsers((prev) => ({ ...prev, firstName: value }))
                }
              />

              <UserFields
                fullWidth
                label="Фамилия"
                value={lastName}
                onChange={({ target: { value } }) =>
                  setMobileUsers((prev) => ({ ...prev, lastName: value }))
                }
              />

              <UserFields
                fullWidth
                label="Email"
                disabled
                value={email}
              />

              <UserCountrySelect
                mobileUsers={mobileUsers}
                countryId={countryId}
                setMobileUsers={handlerSetCountry}
                getAllItemsParams={getAllItemsParams}
              />

              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  fullWidth
                  style={{ marginBottom: "20px", marginLeft: "10px" }}
                  variant="inline"
                  label="День Рождения"
                  format="dd.MM.yyyy"
                  value={birthday}
                  onChange={(value) =>
                    setMobileUsers((prev) => ({
                      ...prev,
                      birthday: `${new Date(value)}`,
                    }))
                  }
                />
              </ThemeProvider>

              <UserSubjectsSelect
                userId={userId}
                subjects={subjects}
                applicationId={applicationId}
                setRequestError={setRequestError}
              />

              <UserFields
                fullWidth
                label="Телефон"
                value={phone}
                onChange={({ target: { value } }) =>
                  setMobileUsers((prev) => ({ ...prev, phone: value }))
                }
              />

              <UserRegionSelect
                schools={schools}
                schoolRegionId={schoolRegionId}
                setMobileUsers={handlerSetRegionId}
                countryId={countryId}
                getAllItemsParams={getAllItemsParams}
              />

              <UserCitiesSelect
                schools={schools}
                regionId={schoolRegionId}
                cityId={schoolCityId}
                setMobileUsers={handlerSetCity}
                getAllItemsParams={getAllItemsParams}
              />

              {/* <UserSchoolsSelect
                userId={userId}
                schools={schools}
                cityId={schoolCityId}
                regionId={schoolRegionId}
                getAllItemsParams={getAllItemsParams}
                updateCityAndRegionHandler={updateCityAndRegionHandler}
              /> */}

              <UserRoleSelect role={role} setUserRoleId={handleSetRole} />

              {role?.role?.includes("PUPIL") && (
                <>
                  <UserSchoolClasses
                    schoolClassroomId={schoolClassroomId}
                    schoolId={schoolId}
                    getAllItemsParams={getAllItemsParams}
                    setMobileUsers={handleSetSchoolRoom}
                  />

                  <UserElectivesByPupil
                    pupilId={userId}
                    getAllItemsParams={getAllItemsParams}
                  />
                </>
              )}

              <Box ml={1} mt={1}>
                {status.id === 6
                  ? "Разблокировать пользователя"
                  : "Заблокировать пользователя"}
              </Box>
              <CustomSwitch
                checked={status?.id === 6}
                onChange={() => {
                  blockUserHandle(userId);
                }}
              />
            </Box>
          </Box>
        </Box>

        <VerticalLine />

        <Box width="48%">
          <Box mt={2} ml={2}>
            <Box fontWeight="bold" mb={2}>
              Дата Регистрации
            </Box>
            <Box>{convertDateStringToLocaleHandler(createdDate)}</Box>
          </Box>

          {personalSchool.length > 0 || nonPersonalSchools?.length > 0 ? (
            <Box mt={2} ml={2}>
              <h4>Пространства</h4>
              {personalSchool.length > 0 && (
                <>
                  <p>Персональное</p>
                  <SchoolList>
                    {personalSchool.map(({ id, name }) => (
                      <SchoolListItem key={id}>
                        <SchoolLink to={`/${SchoolPaths.personal}/${id}/edit`}>
                          {name}
                        </SchoolLink>
                      </SchoolListItem>
                    ))}
                  </SchoolList>
                </>
              )}

              {nonPersonalSchools?.length > 0 && (
                <>
                  <p>Школьное</p>
                  <SchoolList>
                    {nonPersonalSchools.map(({ id, name }) => (
                      <SchoolListItem key={id}>
                        <SchoolLink to={`/${SchoolPaths.school}/${id}/edit`}>
                          {name}
                        </SchoolLink>
                      </SchoolListItem>
                    ))}
                  </SchoolList>
                </>
              )}
            </Box>
          ) : null}
        </Box>

        <Box mt={2} display="flex" width="100%" justifyContent="center">
          <ButtonCustom onClick={updateUserHandle}>Сохранить</ButtonCustom>
        </Box>
      </Box>
    );
  }

  return (
    <Container>
      <Header>Редактирование пользователя</Header>

      {content}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
