import {useEffect, useState, memo, useContext} from "react";
import {
  CreatePersonalSubscriptionV3FromAdminPanelMutationVariables,
  CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables,
  GetPaymentsHistoryBySchoolDocument,
  HandlePaymentActivationMutationVariables,
  useCreatePersonalSubscriptionV3FromAdminPanelMutation,
  useCreateSubscriptionPlanPaymentFromAdminPanelMutation,
  useDeletePaymentMutation,
  useGetPaymentsHistoryBySchoolQuery,
  useGetSubscriptionPlansV2LazyQuery,
  useGetSubscriptionPlansV3ForAdminPanelLazyQuery,
  useHandlePaymentActivationMutation,
} from "../../../generated/graphql";
import { SchoolPayments } from "./SchoolPayments";
import {
  SubscriptionFormPersonalSchool,
  SubscriptionFormSchools,
} from "./SubscriptionFormsForSchools";
import { useRequestError } from "../../../hooks";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../shared/SuccessSnackbar";
import { SubscriptionContainer } from "../Style";
import { Subtitle } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import {
  CustomErrorMessagesMap,
  GqlErrorMessagesMap,
  SchoolPaymentsEnum,
  NO_MANAGER_ERROR,
} from "./maps";
import { getFormattedError, getSchoolSubscriptionPlans } from "./utils";
import { UserInfoContext } from "../../Main";

interface SchoolSubscriptionProps {
  schoolId: string;
  isPersonal: boolean;
  disableFormSubmit: boolean;
  onSubscriptionError(field: string): void;
}

export const SchoolSubscription = ({
  schoolId,
  isPersonal,
  disableFormSubmit,
  onSubscriptionError,
}: SchoolSubscriptionProps) => {
  const isUserCanManageSubscriptions = useContext(
      UserInfoContext
  )?.userPermissions?.includes("CAN_MANAGE_SUBSCRIPTIONS");

  const [
    getSubscriptionForPersonalSchool,
    {
      data: subscriptionPlansData,
      loading: subscriptionPlansLoading,
      error: subscriptionPlansError,
    },
  ] = useGetSubscriptionPlansV3ForAdminPanelLazyQuery({
    variables: { schoolId },
    fetchPolicy: "cache-and-network",
  });
  const [
    getSubscriptionForSchoolsPlans,
    {
      data: subscriptionForSchoolsPlansData,
      loading: subscriptionForSchoolsPlansLoading,
      error: subscriptionForSchoolsPlansError,
    },
  ] = useGetSubscriptionPlansV2LazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const {
    data: paymentsHistoryData,
    loading: paymentsHistoryLoading,
    error: paymentsHistoryError,
  } = useGetPaymentsHistoryBySchoolQuery({
    variables: { schoolId },
    fetchPolicy: "cache-and-network",
  });

  const refetchPaymentsHistory = {
    query: GetPaymentsHistoryBySchoolDocument,
    variables: { schoolId },
  };

  const [
    createPaymentFormAdminPanel,
    {
      data: createPaymentData,
      loading: createPaymentLoading,
      error: createPaymentError,
    },
  ] = useCreateSubscriptionPlanPaymentFromAdminPanelMutation({
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [
    createPersonalSubscriptionV3FromAdminPanel,
    {
      data: createPersonalPaymentData,
      loading: createPersonalPaymentLoading,
      error: createPersonalPaymentError,
    },
  ] = useCreatePersonalSubscriptionV3FromAdminPanelMutation({
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [
    activatePayment,
    {
      data: paymentActivationData,
      loading: paymentActivationLoading,
      error: paymentActivationError,
    },
  ] = useHandlePaymentActivationMutation({
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [
    deletePayment,
    {
      data: paymentDeletionData,
      loading: paymentDeletionLoading,
      error: paymentDeletionError,
    },
  ] = useDeletePaymentMutation({
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const plans =
    subscriptionPlansData?.getSubscriptionPlansV3ForAdminPanel ?? [];

  const plansForSchools =
    subscriptionForSchoolsPlansData?.getSubscriptionPlansV2 ?? [];

  const payments =
    paymentsHistoryData?.getPaymentsHistoryBySchool?.payments ?? [];

  const mutationData =
    createPaymentData?.createSubscriptionPlanPaymentFromAdminPanel ||
    paymentActivationData?.handlePaymentActivation ||
    paymentDeletionData?.deletePayment ||
    createPersonalPaymentData?.createPersonalSubscriptionV3FromAdminPanel;

  const isLoading =
    subscriptionPlansLoading ||
    paymentsHistoryLoading ||
    subscriptionForSchoolsPlansLoading;
  const disableSubmit =
    disableFormSubmit ||
    isLoading ||
    createPaymentLoading ||
    paymentActivationLoading ||
    paymentDeletionLoading ||
    createPersonalPaymentLoading;
  const errors =
    subscriptionPlansError ||
    subscriptionForSchoolsPlansError ||
    paymentsHistoryError ||
    createPaymentError ||
    paymentActivationError ||
    paymentDeletionError ||
    createPersonalPaymentError;

  const errorMessage =
    (errors && GqlErrorMessagesMap.get(getFormattedError(errors.message))) ||
    ((subscriptionPlansError || subscriptionForSchoolsPlansError) &&
      CustomErrorMessagesMap.get(SchoolPaymentsEnum.subscriptionPlans)) ||
    (paymentsHistoryError &&
      CustomErrorMessagesMap.get(SchoolPaymentsEnum.paymentsHistory)) ||
    ((createPaymentError || createPersonalPaymentError) &&
      CustomErrorMessagesMap.get(SchoolPaymentsEnum.createPayment)) ||
    (paymentActivationError &&
      CustomErrorMessagesMap.get(SchoolPaymentsEnum.activatePayment)) ||
    (paymentDeletionError &&
      CustomErrorMessagesMap.get(SchoolPaymentsEnum.deletePayment));

  const { requestError, setRequestError } = useRequestError(errors);

  const handlePaymentCreationForSchools = async (
    variables: CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables
  ) => {
    try {
      await createPaymentFormAdminPanel({
        variables: {
          data: {
            ...variables.data,
            schoolId,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentCreationForPersonalSchool = async (
    variables: CreatePersonalSubscriptionV3FromAdminPanelMutationVariables
  ) => {
    try {
      await createPersonalSubscriptionV3FromAdminPanel({
        variables: {
          data: {
            ...variables.data,
            schoolId,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentActivation = async (
    variables: HandlePaymentActivationMutationVariables
  ) => {
    try {
      await activatePayment({ variables });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentDeletion = async (id: string) => {
    try {
      await activatePayment({ variables: { id, isActivated: false } });
      await deletePayment({ variables: { id } });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (mutationData) {
      setOpenSnackbar(true);
    }
  }, [mutationData]);

  useEffect(() => {
    const isNoManagerError =
      errors && getFormattedError(errors.message) === NO_MANAGER_ERROR;

    if (isNoManagerError) {
      onSubscriptionError("manager");
    }
  }, [errors]);

  useEffect(() => {
    if (isPersonal) {
      getSubscriptionForPersonalSchool();
      return;
    }
    getSubscriptionForSchoolsPlans();
  }, [isPersonal]);

  return (
    <>
      <SuccessSnackbar
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />

      <SubscriptionContainer>
        <Subtitle>Подписки</Subtitle>
        {isUserCanManageSubscriptions && (isPersonal ? (
            <SubscriptionFormPersonalSchool
              subscriptionPlans={plans}
              disableSubmit={disableSubmit}
              createPayment={handlePaymentCreationForPersonalSchool}
            />
          ) : (
            <SubscriptionFormSchools
              subscriptionPlans={getSchoolSubscriptionPlans(plansForSchools)}
              disableSubmit={disableSubmit}
              createPayment={handlePaymentCreationForSchools}
            />
          )
        )}

        {!isLoading ? (
          <SchoolPayments
            payments={payments}
            isPersonal={isPersonal}
            activatePayment={handlePaymentActivation}
            deletePayment={handlePaymentDeletion}
          />
        ) : (
          <CircularProgress color="inherit" />
        )}
      </SubscriptionContainer>
    </>
  );
};
