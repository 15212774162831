import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, IconButton, Tooltip, Snackbar } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Cancel,
  Delete,
  Edit,
  Save,
  RestorePage,
  Adb,
  Apple,
} from "@material-ui/icons";

import { CustomTableInput } from "../../../shared/CustomTable/CustomTable.style";
import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";
import {
  HiddenInput,
  primary,
  PrimaryLink,
  secondary,
} from "../../../shared/Style/Style";

import { SettingsListElement } from "../Settings.style";
import { WebGlIcon } from "../../../shared/Icons/WebGlIcon";
import { UserInfoContext } from "../../Main";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

interface SettingsListItemProps {
  item: {
    id: string;
    name: string;
    file?: string;
    fileIOS?: string;
    fileAndroid?: string;
    fileWebGl?: string;
    code?: string;
    discount?: number;
    startDate?: Date;
    endDate?: Date;
    tariff?: {
      id: string;
      name: string;
    };
  };
  updateItem(
    id: string,
    name: string,
    file?: File,
    fileIOS?: File,
    fileAndroid?: File
  ): void;
  deleteItem(id: string): void;
  buttonsDisabled: boolean;
  isEditing: {
    itemId: string;
    status: boolean;
  };
  setEditing(state: { itemId: string; status: boolean }): void;
  withFile?: boolean;
  withMobileSystemFiles?: boolean;
}

export const SettingsListItem = ({
  item,
  updateItem,
  deleteItem,
  buttonsDisabled,
  isEditing,
  setEditing,
  withFile,
  withMobileSystemFiles,
}: SettingsListItemProps) => {
  const isUserCanCreatePromotion = useContext(
    UserInfoContext
  )?.userPermissions?.includes("CAN_CREATE_PROMOTION");
  const isPromotionDiscount = !!(item.tariff?.id && item.discount);

  const fileInput = useRef<HTMLInputElement>(null);
  const androidFileInput = useRef<HTMLInputElement>(null);
  const iosFileInput = useRef<HTMLInputElement>(null);
  const webGlFileInput = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [androidFile, setAndroidFile] = useState(null);
  const [iosFile, setIosFile] = useState(null);
  const [webGlFile, setWebGlFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleCopy = async (promoLink: string) => {
    try {
      await navigator.clipboard.writeText(promoLink);
      setShowAlert(true);
    } catch (err) {
      console.error("Помилка копіювання:", err);
    }
  };

  const cancelHandler = () => {
    setEditing({
      itemId: "",
      status: false,
    });
    setName("");
    setFile(null);
    setIosFile(null);
    setAndroidFile(null);
    setWebGlFile(null);
  };

  useEffect(() => {
    if (isEditing.itemId === item.id && isEditing.status) {
      setName(item.name);
    }
  }, [isEditing.itemId, isEditing.status]);

  useEffect(() => {
    if (isEditing.itemId !== item.id && !isEditing.status) {
      setName("");
      setFile(null);
      setIosFile(null);
      setAndroidFile(null);
      setWebGlFile(null);
    }
  }, [isEditing.itemId, isEditing.status]);

  useEffect(() => {
    if (item) {
      setFile(null);
      setIosFile(null);
      setAndroidFile(null);
      setWebGlFile(null);
    }
  }, [item]);

  const renderFileInput = (
    inputRef: React.RefObject<HTMLInputElement>,
    setFileState: React.Dispatch<React.SetStateAction<File | null>>
  ) => (
    <HiddenInput
      type="file"
      ref={inputRef}
      disabled={buttonsDisabled}
      onFocus={() => {
        setFileState(null);
        inputRef.current.value = "";
      }}
      onChange={({ target: { files } }) => setFileState(files[0])}
    />
  );

  return (
    <SettingsListElement button={true}>
      <Box minWidth="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Box width={isPromotionDiscount ? 270 : 300}>
            {!isPromotionDiscount ? (
              <CustomTableInput
                disabled={isEditing.itemId !== item.id}
                value={
                  isEditing.itemId === item.id && isEditing.status
                    ? name
                    : item.name
                }
                onChange={({ target }) => setName(target.value)}
              />
            ) : (
              <div>{item.name}</div>
            )}
          </Box>
          <Box>
            {isPromotionDiscount && (
              <>
                <Tooltip title="Копіювати посилання">
                  <IconButton
                    onClick={() =>
                      handleCopy(
                        `${process.env.REACT_APP_AR_TEACHER_BASE_URL}/direct/promotion?ref=${item.code}`
                      )
                    }
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                <Snackbar
                  open={showAlert}
                  autoHideDuration={2000}
                  onClose={() => setShowAlert(false)}
                >
                  <Alert onClose={() => setShowAlert(false)} severity="success">
                    Посилання скопійовано!
                  </Alert>
                </Snackbar>
              </>
            )}
            {isPromotionDiscount && !isUserCanCreatePromotion ? null : (
              <>
                <IconButton
                  disabled={
                    buttonsDisabled ||
                    (isEditing.itemId !== item.id && isEditing.status)
                  }
                  onClick={() => {
                    if (isEditing.itemId !== item.id && !isEditing.status) {
                      setEditing({
                        itemId: item.id,
                        status: true,
                      });
                      return;
                    }

                    if (withFile) {
                      updateItem(item.id, name, file);
                      return;
                    }

                    if (withMobileSystemFiles) {
                      updateItem(
                        item.id,
                        name,
                        iosFile,
                        androidFile,
                        webGlFile
                      );
                      return;
                    }
                    updateItem(item.id, name);
                  }}
                >
                  {isEditing.itemId === item.id && isEditing.status ? (
                    <Save />
                  ) : (
                    <Edit />
                  )}
                </IconButton>
                <IconButton
                  disabled={
                    buttonsDisabled ||
                    (isEditing.itemId !== item.id && isEditing.status)
                  }
                  onClick={() => {
                    isEditing.status ? cancelHandler() : deleteItem(item.id);
                  }}
                >
                  {isEditing.itemId === item.id && isEditing.status ? (
                    <Cancel color="secondary" />
                  ) : (
                    <Delete color="secondary" />
                  )}
                </IconButton>
              </>
            )}
          </Box>
        </Box>

        {withFile && (
          <>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {file?.name || (
                <PrimaryLink href={item.file}>Background</PrimaryLink>
              )}
              <CustomIconButton
                disabled={
                  buttonsDisabled ||
                  isEditing.itemId !== item.id ||
                  !isEditing.status
                }
                mainColor={primary}
                disabledColor={secondary}
                callback={() => {
                  fileInput.current.click();
                }}
                children={<RestorePage color="inherit" />}
              />
            </Box>

            <Box position="absolute">
              <HiddenInput
                type="file"
                ref={fileInput}
                disabled={buttonsDisabled}
                onFocus={() => {
                  setFile(null);
                  fileInput.current.value = "";
                }}
                onChange={({ target: { files } }) => setFile(files[0])}
              />
            </Box>
          </>
        )}

        {withMobileSystemFiles && (
          <>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flexWrap="wrap"
            >
              <Box>
                {iosFile?.name || (
                  <PrimaryLink href={item.fileIOS}>IOS background</PrimaryLink>
                )}
                <CustomIconButton
                  disabled={
                    buttonsDisabled ||
                    isEditing.itemId !== item.id ||
                    !isEditing.status
                  }
                  mainColor={primary}
                  disabledColor={secondary}
                  callback={() => {
                    iosFileInput.current.click();
                  }}
                  children={<Apple color="inherit" />}
                />
              </Box>

              <Box>
                {androidFile?.name || (
                  <PrimaryLink href={item.fileAndroid}>
                    Android background
                  </PrimaryLink>
                )}
                <CustomIconButton
                  disabled={
                    buttonsDisabled ||
                    isEditing.itemId !== item.id ||
                    !isEditing.status
                  }
                  mainColor={primary}
                  disabledColor={secondary}
                  callback={() => {
                    androidFileInput.current.click();
                  }}
                  children={<Adb color="inherit" />}
                />
              </Box>
              <Box>
                {webGlFile?.name || (
                  <PrimaryLink href={item.fileWebGl}>
                    WebGl background
                  </PrimaryLink>
                )}
                <CustomIconButton
                  disabled={
                    buttonsDisabled ||
                    isEditing.itemId !== item.id ||
                    !isEditing.status
                  }
                  mainColor={primary}
                  disabledColor={secondary}
                  callback={() => {
                    webGlFileInput.current.click();
                  }}
                  children={<WebGlIcon />}
                />
              </Box>
            </Box>

            <Box position="absolute">
              {renderFileInput(iosFileInput, setIosFile)}

              {renderFileInput(androidFileInput, setAndroidFile)}

              {renderFileInput(webGlFileInput, setWebGlFile)}
            </Box>
          </>
        )}
      </Box>
    </SettingsListElement>
  );
};
