import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { blockNameText } from "../Courses/constants";
import { ModalInput } from "../../shared/Style/Style";
import { Course } from "../../../generated/graphql";

interface SelectCoursesProps {
  courses: Course[];
  activeCourse: Course;
  setCourseId: (courseId) => void;
  onRemoveCourse: () => Promise<void>;
}

export const SelectCourses = ({
  courses = [],
  activeCourse,
  setCourseId,
  onRemoveCourse,
}: SelectCoursesProps) => {
  const handleChange = async (value) => {
    if (value === null) await onRemoveCourse();
    setCourseId(value?.id);
  };

  useEffect(() => {
    setCourseId(courses[0]?.id);
  }, []);

  return (
    <Autocomplete
      filterSelectedOptions
      options={courses}
      defaultValue={courses[0]}
      getOptionLabel={(option) => `${option.name} (${option.description})`}
      noOptionsText="Курс с таким названием не найден"
      clearText="Убрать доступ к курсу"
      disableClearable={!activeCourse?.courseIsAvailable}
      onChange={(_, value) => handleChange(value)}
      renderInput={(params) => (
        <ModalInput label={blockNameText} variant="filled" {...params} />
      )}
    />
  );
};
