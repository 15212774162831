import { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CloseIconButton } from "../../../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  CancelButton,
  defaultMaterialTheme,
} from "../../../shared/Style/Style";
import {
  DateRangeContent,
  DateRangeInput,
} from "../../../shared/DateRangePicker/DateRangePicker.style";
import {
  GetManualPromotionsDocument,
  useCreateManualPromotionMutation,
  useGetSubscriptionPlansForManualPromotionQuery,
  useUpdateManualPromotionMutation,
} from "../../../../generated/graphql";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  row-gap: 16px;
  margin-bottom: 48px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const minDiscount = 1;
const maxDiscount = 99;

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const defaultDiscount = 10;

interface Tariff {
  id: string;
  name: string;
}

interface Discount {
  id: string;
  name: string;
  discount: number;
  startDate: Date;
  endDate: Date;
  tariff: Tariff;
}

interface ManualDiscountsModalProps {
  onClose(): void;
  clearName(): void;
  currentDiscount: Discount;
}

export const ManualDiscountsModal = ({
  onClose,
  currentDiscount,
  clearName,
}: ManualDiscountsModalProps) => {
  const [promotion, setPromotion] = useState(null);
  const [createPromotion] = useCreateManualPromotionMutation();
  const [updatePromotion] = useUpdateManualPromotionMutation();
  const isEditMode = !!currentDiscount.id;

  const { data: tariffsData } =
    useGetSubscriptionPlansForManualPromotionQuery();

  const tariffs =
    tariffsData?.getSubscriptionPlansForManualPromotion?.map((tariff) => ({
      id: tariff.id,
      name: `${tariff.description}, ${tariff.monthsCount} міс.`,
    })) || [];

  const handleUpdatePromotion = (updatedFields: Partial<Discount>) => {
    setPromotion((prevDiscount) => ({
      ...prevDiscount,
      ...updatedFields,
    }));
  };

  useEffect(() => {
    if (promotion || !tariffsData) return;
    // edit mode
    if (isEditMode) {
      return handleUpdatePromotion(currentDiscount);
    }
    // new promotion has only name
    const popularTariff = tariffs.find(
      (tariff) => tariff.name === "Вчитель +, 12 міс."
    );
    handleUpdatePromotion({
      name: currentDiscount.name,
      discount: defaultDiscount,
      startDate: today,
      endDate: tomorrow,
      tariff: popularTariff,
    });
  }, [currentDiscount, tariffsData, promotion, isEditMode]);

  if (!promotion) return null;

  return (
    <Dialog open>
      <Box display="flex" justifyContent="flex-end">
        <CloseIconButton close={onClose} />
      </Box>
      <DialogContent style={{ minWidth: 600, padding: 30 }}>
        <GridWrapper>
          <Label>Назва</Label>
          <TextField
            required
            error={!promotion.name}
            value={promotion.name}
            onChange={(e) => handleUpdatePromotion({ name: e.target.value })}
          />
          {!promotion.name && (
            <Box
              sx={{
                position: "absolute",
                top: 62,
                left: 192,
                fontSize: 10,
                color: "#F44336",
              }}
            >
              Введіть назву
            </Box>
          )}
          <Label>Тариф</Label>
          <Select
            value={promotion.tariff.id}
            onChange={(e) => {
              const selectedTariff = tariffs.find(
                (tariff) => tariff.id === e.target.value
              );
              handleUpdatePromotion({ tariff: selectedTariff });
            }}
          >
            {tariffs.map((tariff) => (
              <MenuItem value={tariff.id}>{tariff.name}</MenuItem>
            ))}
          </Select>
          <Label>Знижка, %</Label>
          <TextField
            required
            error={!promotion.discount}
            type="number"
            value={promotion.discount}
            onKeyDown={(e) =>
              (e.key === "-" || e.key === "+") && e.preventDefault()
            }
            onChange={(e) => {
              const value = e.target.value;
              if (!value) {
                return handleUpdatePromotion({ discount: null });
              }
              const numberValue = Number(value);
              if (numberValue >= minDiscount && numberValue <= maxDiscount) {
                handleUpdatePromotion({ discount: numberValue });
              }
            }}
            inputProps={{
              min: minDiscount,
              max: maxDiscount,
            }}
          />
          {!promotion.discount && (
            <Box
              sx={{
                position: "absolute",
                top: 158,
                left: 192,
                fontSize: 10,
                color: "#F44336",
              }}
            >
              Введіть знижку
            </Box>
          )}
          <Label>Термін дії</Label>
          <DateRangeContent style={{ padding: 0 }}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <DateRangeInput
                variant="inline"
                autoOk
                format="dd.MM.yyyy"
                label="Початок періоду"
                value={promotion.startDate}
                maxDateMessage="Дата початку пізніша за дату закінчення"
                maxDate={promotion.endDate}
                onChange={(value) =>
                  handleUpdatePromotion({ startDate: value })
                }
              />
              <DateRangeInput
                autoOk
                variant="inline"
                format="dd.MM.yyyy"
                label="Кінець періоду"
                value={promotion.endDate}
                minDateMessage="Дата закінчення раніше за дату початку"
                minDate={promotion.startDate}
                onChange={(value) => handleUpdatePromotion({ endDate: value })}
              />
            </ThemeProvider>
          </DateRangeContent>
        </GridWrapper>
        <Box display="flex" justifyContent="space-between">
          <CancelButton onClick={onClose} disabled={false}>
            Скасувати
          </CancelButton>
          <ButtonCustom
            disabled={!promotion.name || !promotion.discount}
            onClick={() => {
              const data = {
                name: promotion.name,
                discount: promotion.discount,
                startDate: promotion.startDate,
                expiredDate: promotion.endDate,
                planIds: [promotion.tariff.id],
              };
              isEditMode
                ? updatePromotion({
                    variables: { updateManualPromotionId: promotion.id, data },
                    refetchQueries: [{ query: GetManualPromotionsDocument }],
                  })
                : createPromotion({
                    variables: { data },
                    refetchQueries: [{ query: GetManualPromotionsDocument }],
                  }).then(clearName);
              onClose();
            }}
          >
            Зберегти
          </ButtonCustom>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
